import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const Therapies = ({ data }) => {
  const { edges: pages } = data.allMarkdownRemark;

  return (
    <div className="columns is-multiline">
      {pages &&
      pages.map(({ node: page }) => (
        <div className="is-parent column is-6" key={page.id}>
          <article
            className={`blog-list-item tile is-child box notification`}
          >
            <header>
              {page.frontmatter.image ? (
                <div className="featured-thumbnail">
                  <PreviewCompatibleImage
                    imageInfo={{
                      image: page.frontmatter.image,
                      alt: `featured image thumbnail for post ${page.frontmatter.title}`,
                    }}
                  />
                </div>
              ) : null}
              <p className="post-meta">
                <Link
                  className="title has-text-primary is-size-4"
                  to={page.fields.slug}
                >
                  {page.frontmatter.title}
                </Link>
              </p>
            </header>
            <p>
              {page.frontmatter.description}
              <br />
              <br />
              <Link className="button" to={page.fields.slug}>
                Weiterlesen →
              </Link>
            </p>
          </article>
        </div>
      ))}
    </div>
  );
}

Therapies.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query TherapiesQuery {
        allMarkdownRemark(
          filter: { frontmatter: { templateKey: { eq: "therapy-page" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                description
                image {
                  childImageSharp {
                    fluid(maxWidth: 120, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <Therapies data={data} count={count} />}
  />
);
